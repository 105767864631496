.our-service {
  background-color: #f6defc;
}

.our-service-box {
  display: flex;
  align-items: center !important;
  flex-direction: column;
  margin-bottom: 15px;
  margin-top: 15px;
  height: 90%;
  text-align: center;
  webkit-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: #ffffff;
  border-bottom: 3px solid var(--blue);
}

.our-service-box:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
  box-shadow: 7px 5px 30px rgba(72, 73, 121, .15);

}



