.our-portfolio {
  background: var(--bg-lightgray);
}

.portfolio-box h5 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.portfolio-box {
  margin-bottom: 30px;
}

.portfolio-img {
  overflow: hidden;
  width: fit-content;
}

.portfolio-img img {
  transform: scale(1);
  transition: 0.5s;
  height: 15rem;
}

.portfolio-img:hover img {
  transform: scale(1.2);
  transition: 0.5s;
}

.our-portfolio .row .col-md-4:nth-child(4) .portfolio-box,
.our-portfolio .row .col-md-4:nth-child(5) .portfolio-box,
.our-portfolio .row .col-md-4:nth-child(6) .portfolio-box {
  margin-bottom: 0;
}