/* About us page csss */
.about-box {
  background: var(--bg-lightgray);
}

.about-img {
  margin: 0 0 -3rem 8rem;
}

.about-img img {
  width: 17rem;
}

/* .about-box::after {
  opacity: 0.8;
  position: absolute;
  background-color: #222d39;
}

.progress-bar {
  background-color: var(--blue);
}

.progress {
  height: 0.7rem;
  border-radius: 25px;
}

.about-box .carousel-indicators li {
  height: 30px;
  width: 30pox;
  background-color: var(--grey);
  border-radius: 100%;
}

.why-choose {
  text-align: center;
  background: #fff;
  margin-top: 20px;
}

.why-box {
  padding: 15px;
}

.why-choose .col-md-4:first-child .why-box {
  border-bottom: 1px solid #eee;
}

.why-choose .col-md-4:nth-child(2) .why-box {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}

.why-choose .col-md-4:nth-child(3) .why-box {
  border-bottom: 1px solid #eee;
}

.why-choose .col-md-4:nth-child(5) .why-box {
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}

.why-box i {
  font-size: 35px;
  color: var(--grey);
  transition: .4s;
}

.why-box h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 8px;
}

.slick-dots li.slick-active button:before {
  color: var(--blue) !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  color: #7c7c7c;
  opacity: 0.25;
  font-size: 14px;
} */